import React from 'react';
import 'whatwg-fetch';

import { TechnologyTypes } from '../utils/constants';

import styles from './App.module.css';
import { Chart } from 'ui/chart/Chart';
import { ChartProps } from 'ui/chart/Chart.types';
import { Sidebar } from 'ui/sidebar/Sidebar';
import { useItemsState } from 'hooks/useItems';

const colors = {
  background: '#fff',
  grid: '#ddd',
  inactive: '#ddd',
};

const circles: ChartProps['circles'] = [
  {
    name: TechnologyTypes.Avoid,
    r: 360,
    classNameCircle: 'circle-avoid',
  },
  {
    name: TechnologyTypes.Experiment,
    r: 270,
    classNameCircle: 'circle-experiment',
  },
  {
    name: TechnologyTypes.Sometimes,
    r: 180,
    classNameCircle: 'circle-sometimes',
  },
  {
    name: TechnologyTypes.Common,
    r: 90,
    classNameCircle: 'circle-common',
  },
];

const titles = [
  {
    name: 'Avoid',
    r: 360,
  },
  {
    name: 'Experiment',
    r: 270,
  },
  {
    name: 'Sometimes',
    r: 180,
  },
  {
    name: 'Common',
    r: 90,
  },
];

export const chartConfig = {
  colors,
  circles,
  titles,
};

export function App() {
  const items = useItemsState();

  return (
    <div className={styles.app}>
      <Sidebar items={items.filteredItems} />
      <Chart {...chartConfig} items={items.itemsWithCoordinates} />
    </div>
  );
}
