export const getRandomFromRange = (min: number, max: number) => Math.random() * (max - min) + min;

export const selectItemsByFieldValue = ({
  items = [],
  field,
  value,
}: {
  items: { [key: string]: string }[];
  field: string;
  value: string;
}) => items.filter((item) => item[field].toLowerCase() === value.toLowerCase());

export const sortAlphabeticallyByField = (items: { [key: string]: string }[], field: string) =>
  [...items].sort((a, b) => a[field].localeCompare(b[field]));

export const simplifyString = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
