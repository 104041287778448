import { selectItemsByFieldValue } from './common';
import { ItemWithCategory } from 'ui/sidebar/Sidebar.types';

export const groupDataByFields = (items: ItemWithCategory[] = [], objWithKeys: object, field: string) =>
  Object.keys(objWithKeys).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: selectItemsByFieldValue({ items, field, value: curr }),
    }),
    {},
  );
