import React, { memo } from 'react';

import { LogoTSH } from 'ui/svg/logo/LogoTSH';
import styles from './Sidebar.module.css';
import { Accordion } from 'ui/accordion/Accordion';
import { TechnologyTypes } from 'utils/constants';
import { Search } from 'ui/search/Search';
import { SidebarProps } from './Sidebar.types';
import { groupDataByFields } from 'utils/sidebar';
import { Teams } from 'ui/teams/Teams';

export const Sidebar = memo(function Sidebar({ items }: SidebarProps) {
  const groupedData = groupDataByFields(items, TechnologyTypes, 'type');

  return (
    <div className={styles.sidebar} data-testid="sidebar">
      <a className={styles.logoLink} href="/">
        <LogoTSH />
      </a>
      <h1 className={styles.title}>Tech Radar</h1>
      <h2 className={styles.subtitle}>Search for our best technologies</h2>
      <Search />
      <Teams />
      <Accordion data={groupedData} />
    </div>
  );
});
