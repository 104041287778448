import React from 'react';

import styles from './AccordionItem.module.css';
import { useSetActiveItem } from 'hooks/useActiveItem';
import { AccordionItemProps } from './AccordionItem.types';

export function AccordionItem({ name, type, displayName }: AccordionItemProps) {
  const setActiveItem = useSetActiveItem();

  return (
    <li
      className={styles.item}
      onMouseEnter={() => {
        setActiveItem({ name, type });
      }}
      onMouseLeave={() => {
        setActiveItem({ name: '', type: '' });
      }}
    >
      {displayName}
    </li>
  );
}
