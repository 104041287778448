import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { IoIosArrowUp } from 'react-icons/io';
import CountUp from 'react-countup';

import { AccordionItem } from './item/AccordionItem';
import { sortAlphabeticallyByField } from 'utils/common';
import styles from './AccordionSection.module.css';
import { AccordionSectionProps } from './AccordionSection.types';
import { useItemsState } from 'hooks/useItems';
import { debounce } from 'utils/debounce/debounce';

const placeholderDescription =
  'Angular is still evolving and there are already many companies using its newest versions.';

const boldPhrase = (text: string, phraseToBold: string) => {
  const boldedText = phraseToBold
    ? text.replace(new RegExp(`(^|.*)(${phraseToBold})(.*|$)`, 'ig'), '$1<strong>$2</strong>$3')
    : text;

  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: boldedText }} />;
};

export function AccordionSection({ type, description = placeholderDescription, items }: AccordionSectionProps) {
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [prevCount, setPrevCount] = useState(0);
  const sortedItems = sortAlphabeticallyByField(items, 'name');
  const { search, filterTeam } = useItemsState();

  useEffect(() => {
    setShow(!!search || !!filterTeam);
  }, [search, filterTeam]);

  useEffect(() => {
    debounce(() => {
      if (count !== items.length) {
        setPrevCount(count);
        setCount(items.length);
      }
    }, 500)();
  }, [count, items.length, type]);

  return (
    <div className={styles.section}>
      <h3 className={styles.title}>
        <button
          className={styles.titleContent}
          type="button"
          onClick={() => {
            setShow(!show);
          }}
        >
          {type}{' '}
          <span id={`${type}-count`} className={styles.badge}>
            <CountUp start={prevCount} end={count} duration={2} />
          </span>
          <div className={styles.arrowContainer}>
            <IoIosArrowUp className={clsx(styles.arrow, !show && styles.arrowCollapsed)} />
          </div>
        </button>
      </h3>
      {description && !search && <p className={styles.description}>{description}</p>}
      <ul className={clsx(styles.items, !show && styles.hidden)}>
        {sortedItems.map(({ name }) => (
          <AccordionItem key={name} name={name} displayName={boldPhrase(name, search)} type={type.toLowerCase()} />
        ))}
      </ul>
    </div>
  );
}
