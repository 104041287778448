import React, { useEffect, useState, useCallback, ReactNode } from 'react';

import { Item, ItemWithCoordinates } from 'ui/chart/Chart.types';
import { rangeByType, colorByCategory } from 'utils/chart';
import { getRandomFromRange } from 'utils/common';
import {
  ItemsStateContext,
  ItemsDispatchContext,
  initialItemsContextState,
  itemPointsRadius,
  titlePoints,
} from './ItemsContext';
import { ItemsContextState, ApiResponse, ItemsContextDispatch } from './ItemsContext.types';
import { ItemContextControllerProps } from './ItemsContextController.types';
import { ItemWithCategory } from 'ui/sidebar/Sidebar.types';
import { techResponse } from 'utils/data/response.mock';

export function ItemsContextController({ children }: ItemContextControllerProps) {
  const [state, setState] = useState<ItemsContextState>(initialItemsContextState);

  const setItemsWithCoordinates = (items: ItemWithCategory[]) => {
    const itemsWithCoordinates: ItemWithCoordinates[] = items
      .map(({ category, ...attr }) => {
        const random = getRandomFromRange(0, 2 * Math.PI);
        const { r1, r2 } = rangeByType[attr.type];

        return {
          ...attr,
          r: itemPointsRadius,
          color: colorByCategory[category],
          x: getRandomFromRange(r1 + 12, r2 - 12) * Math.cos(random),
          y: getRandomFromRange(r1 + 12, r2 - 12) * Math.sin(random),
        };
      })
      .concat(titlePoints);
    setState((s) => ({ ...s, itemsWithCoordinates }));
  };

  const setFilteredItems = useCallback(() => {
    // eslint-disable-next-line prefer-destructuring
    let items = state.items;

    if (state.filterTeam) {
      const filteredItems = items.filter((item) => item.teams.includes(state.filterTeam));
      items = filteredItems;
    }

    if (state.search) {
      const searchWord = new RegExp(`(${state.search})`, 'gi');
      const filteredItems = items.filter(({ name }) => searchWord.test(name));
      items = filteredItems;
    }

    setState((s) => ({ ...s, filteredItems: items }));
  }, [state.filterTeam, state.items, state.search]);

  const setItems = useCallback((i: Record<string, Item[]>) => {
    const items: ItemWithCategory[] = Object.entries(i)
      .map(([catName, dataItems]) =>
        dataItems.map((item) => ({
          ...item,
          category: catName,
        })),
      )
      .reduce((acc, curr) => [...acc, ...curr], []);

    setState((s) => ({
      ...s,
      items,
    }));

    setItemsWithCoordinates(items);
  }, []);

  // dictionary fallback for old version of backend API
  const setTeams = (teams: string[], dictionary: Record<string, string> = techResponse.dictionary) => {
    setState((s) => ({
      ...s,
      teams: teams.map((team) => ({ name: team, displayName: dictionary[team] })),
    }));
  };

  // fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetch(process.env.REACT_APP_API_URL);
        const data: ApiResponse = await result.json();
        setItems(data.items);
        setTeams(data.teams, data.dictionary);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };
    fetchData();
  }, [setItems]);

  // update filtered items
  useEffect(() => {
    if (Object.keys(state.items).length) {
      setFilteredItems();
    }
  }, [setFilteredItems, state.items, state.search, state.filterTeam]);

  return (
    <ItemsStateContext.Provider value={state}>
      <ItemsDispatchContext.Provider value={setState}>{children}</ItemsDispatchContext.Provider>
    </ItemsStateContext.Provider>
  );
}

export function ItemContextControllerWrapper({
  state,
  setState,
  children,
}: {
  state: ItemsContextState;
  setState: ItemsContextDispatch;
  children: ReactNode;
}) {
  return (
    <ItemsStateContext.Provider value={state}>
      <ItemsDispatchContext.Provider value={setState}>{children}</ItemsDispatchContext.Provider>
    </ItemsStateContext.Provider>
  );
}
